<template>
    <v-snackbar :top="$vuetify.breakpoint.mdAndUp" :right="$vuetify.breakpoint.mdAndUp" :bottom="$vuetify.breakpoint.smAndDown" v-model="snackbar" :timeout="3000">
        {{ snackbarMsg }}
        <v-btn
                color="primary"
                text
                @click="snackbar = false"
        >
            Close
        </v-btn>
    </v-snackbar>
</template>

<script>
    export default {
        name: "GenericSnackBar",
        data: function () {
            return {
                snackbar: false,
                snackbarMsg: ''
            }
        },
        methods: {
            show(msg) {
                this.snackbar = true;
                this.snackbarMsg = msg;
            }
        }
    }
</script>

<style scoped>

</style>